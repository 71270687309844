import Axios from 'axios'

//const apiUrl = 'https://dev-reservations.kertios.com/api/'
const apiUrl = 'https://reservations.hors-pair.fr/api/'

export function httpGet (uri) {
  return Axios
    .get(apiUrl + uri)
    .then(response => {
      if ('Error' in response.data) {
        /* Vue.notify({
          group: 'app',
          type: 'error',
          title: 'Une erreur est survenue',
          text: response.data.Error,
        }) */
      } else {
        return response.data
      }
    })
    /* .catch(error => {
      console.log(error)
       Vue.notify({
        group: 'app',
        type: 'error',
        title: 'Une erreur est survenue',
        text: error,
      })
    }) */
}

export function httpPost (uri, data, headers = '') {
  return Axios({
    method: 'post',
    url: apiUrl + uri,
    data: data,
    headers: headers,
  })
    .then(response => {
      if ('Error' in response.data) {
        /* Vue.notify({
          group: 'app',
          type: 'error',
          title: 'Une erreur est survenue',
          text: response.data.Error,
        }) */
        return response.data
      } else {
        return response.data
      }
    })
  /* .catch(error => {
    console.log(error)
     Vue.notify({
      group: 'app',
      type: 'error',
      title: 'Une erreur est survenue',
      text: error,
    })
  }) */
}
